/* https://medium.com/@weberzt/importing-a-google-font-into-your-react-app-using-material-ui-773760ded532 
https://fonts.google.com/specimen/Poppins
*/
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */

* {
  font-size: 1rem ;
   font-family: 'Poppins' , sans-serif ;
}


.App {
  text-align: center;

  min-height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.MuiButton-containedPrimary {
  color: #fff !important; 
}

  .App-header {
  background-color: #ffffff;
  /*  min-height: 100vh;
 display: flex; 
  flex-direction: column;*/
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
 
} 

.sq-payment-form {
  color: #373F4A;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  position: relative;
  width: 100% !important;
}

stickToBottom  {
  width: 100%;
  
   position: fixed !important;
 
    bottom: 0px !important;
    height: 56px;
    margin:0px !important;
}  

 

header{
  margin-bottom: 80px;   
 }

main{
  min-height: calc(100vh - 136px); /*footer 56px and header 80*/ 
    display:flex; 
}

.App-link {
  color: #61dafb;
}

.sqlogo {
    display: block;
    width: 24px;
    height: 24px;
    fill: #71767B;
    margin: 0 auto 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 

 

.center {
  margin: auto; 
   width: 50vh;  
    border: 3px solid white; 
    padding: 0px;
}
  
 

 
.responsive {
  width: 100%;
  height: auto;
}
 
.MuiBox-root{
  text-align: left;
}

 .block-title-1{
  color:#000;/* #fff;*/
  background-color: #e4000d;
  background-color:#ffc5e1;/* #002d66;*/
  width:100%;
  font-size:20px;
  font-weight: 400;
  line-height: 36px;
  margin-top:0px;
  padding-left:10px;
  padding-right:10px;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
 /* border-bottom: 1px solid rgba(255, 255, 255, 0.065) */
 }

 .block-title-breadcrumbs{
  /* color: #fff; */
  /* background-color: #e4000d; */
   /* background-color: #cb94af; */
  width:100%;
  font-size:20px;
  font-weight: 400;
  line-height: 36px;
  margin-top:0px;
  padding-left:0px;
  padding-right:10px;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
 /* border-bottom: 1px solid rgba(255, 255, 255, 0.065) */
 }

 .news .item {
  margin-bottom: 1.625em;
  background-color: rgba(245,245,247,.9);
  background-color: #e4000d;
}
.news .item, .news .item-small {
  display: table;
  width: 80% !important;
  position: relative;
}

.pull-right {
  float: right!important;
}

.goBottomParrent {
  position: relative;
  height:100%; 
  background-color: #e4000d,
} 
.goBottom{
  position: absolute;
  bottom: 0;
} 

.dateFeatured{
  font-size:12px;
}
 


.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 80px!important;
  width: 40px!important;
}


/* https://github.com/xiaolin/react-image-gallery/issues/339
*/
.image-gallery {
  width: 70%;
  height: auto; 
  margin: auto; 
}

   /* Medium devices (tablets, 768px and up) */
 @media ( max-width: 768px) { 
  .image-gallery {
    width: 80%;
    height: auto; 
    margin: auto; 
  } 
  }

@media (max-width: 576px) { 
  .image-gallery {
    width: 100%;
    height: auto; 
    margin: auto; 
  } 
 }



.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

white {
  color:#ffffff !important;
}
